<template>
  <div class="page-wrapper">

    <section class="hero">
      <h1 class="title" data-inview="fadeInUp" data-delay="200">
        {{ contentIsLoaded ? content.hero.heading : $t('Virtual production studio in Montreal') }}
      </h1>
      <a href="#about" v-scroll-to:-80 class="down">
        <p>{{ contentIsLoaded ? content.hero.callToAction : $t('Discover our studio') }}</p>
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow down">
      </a>
      <div class="gradient"></div>
      <div class="opacity"></div>
      <video class="fullscreen" src="@/assets/img/mels-hero.mp4" playsinline autoplay muted loop></video>
    </section>

    <section class="about" id="about">
      <h2 class="main-title">{{ contentIsLoaded ? content.heading1 : $t('About the studio') }}</h2>
      <div class="bloc-txt" data-inview="fadeInUp" data-delay="200">
        <div class="title" v-if="contentIsLoaded">
          <img :src="content.aboutInfos[0].icon.url" :alt="content.aboutInfos[0].icon.title">
          <h3 class="main-title small">{{ contentIsLoaded ? content.aboutInfos[0].heading : $t('Optimal Location') }}</h3>
        </div>
        <p class="regular-text" v-if="contentIsLoaded"><span v-html="content.aboutInfos[0].text"></span></p>
      </div>
      <div class="bloc-txt" data-inview="fadeInUp" data-delay="400">
        <div class="title" v-if="contentIsLoaded">
          <img :src="content.aboutInfos[1].icon.url" :alt="content.aboutInfos[1].icon.title">
          <h3 class="main-title small left">{{ contentIsLoaded ? content.aboutInfos[1].heading : $t('Fire power') }}</h3>
        </div>
        <p class="regular-text left" v-if="contentIsLoaded"><span v-html="content.aboutInfos[1].text"></span></p>
      </div>
    </section>

    <section class="techspecs" id="techspecs" v-if="pageIsLoaded">

      <div class="title-wrap">
        <h2 class="main-title">{{ content.heading2 }}</h2>
        <a :href="content.fileDownload.file" target="_blank" class="cta outline"><p>{{ content.fileDownload.label }}</p></a>
      </div>

      <div class="carrousel-menu">
        <button :class="{ active: position == 0 }" @click="goTo(0)">{{ content.contentSlider[0].heading }}</button>
        <button :class="{ active: position == 1 }" @click="goTo(1)">{{ content.contentSlider[1].heading }}</button>
        <button :class="{ active: position == 2 }" @click="goTo(2)">{{ content.contentSlider[2].heading }}</button>
        <button :class="{ active: position == 3 }" @click="goTo(3)">{{ content.contentSlider[3].heading }}</button>
      </div>

      <div class="carousel-tech" >

        <div class="carousel-cell" v-for="(item, i) in contentSlider" :key="'cs-' + i">
          <div class="img" data-inview="revealRightDark" data-delay="200">
            <img :src="item.image.url" :alt="item.image.title">
          </div>
          <div class="data" data-inview="slideLeft" data-delay="400">
            <h3 class="main-title small">{{ item.heading }}</h3>
            <div v-html="item.text"></div>
          </div>
        </div>

      </div>

      <button @click="previous()" class="button-left"><img src="@/assets/img/arrow-left.svg" alt="Arrow left"></button>
      <button @click="next()" class="button-right"><img src="@/assets/img/arrow-right.svg" alt="Arrow right"></button>

    </section>

    <section class="technology" id="technology" v-if="pageIsLoaded">

      <div class="bloc-txt-img">
        <div class="img techno-1" data-inview="revealRightDark" data-delay="200" :style="'background-image: url('+content.split.image.url+');'"></div>
        <div class="data">
          <div class="content" data-inview="slideLeft" data-delay="400">
            <h3 class="main-title">{{ content.split.heading }}</h3>
            <div v-html="content.split.text"></div>
          </div>
        </div>
      </div>

      <!-- <div class="bloc-txt-img">
        <div class="data">
          <div class="content" data-inview="slideRight" data-delay="400">
            <h3 class="main-title">Tracking systems</h3>
            <ul>
              <li class="regular-text">NCAM - MKII PERMANENT CEILING INSTALLATION</li>
              <li class="regular-text">(OPTIONAL) OPTITRACK - 14 cameras x Prime41</li>
              <li class="regular-text">(Active and Passive) </li>
            </ul>
          </div>
        </div>
        <div class="img techno-2" data-inview="revealLeftDark" data-delay="200"></div>
      </div>

      <div class="bloc-txt-img">
        <div class="img techno-3" data-inview="revealRightDark" data-delay="200"></div>
        <div class="data">
          <div class="content" data-inview="slideLeft" data-delay="400">
            <h3 class="main-title">LED Elements</h3>
            <ul>
              <li class="regular-text">708pcs Bp2V2 Tiles - BackGround LED Wall</li>
              <li class="regular-text">36pcs Bp2V2 Tiles 2 x 6 x 6 Tiles Side Panels Movable</li>
              <li class="regular-text">32pcs BM4 (Option Floor Wall. Not shown in the draw)</li>
              <li class="regular-text">104pcs CB5 Tiles - Ceiling split in 2 Motorized sections with 6 DOF</li>
              <li class="regular-text">Brompton SX40 Processors</li>
              <li class="regular-text">Hydra Calibrated for Color Science</li>
            </ul>
          </div>
        </div>
      </div> -->

    </section>

    <section class="faq" id="faq" v-if="pageIsLoaded">
      <h2 class="main-title">{{ $t('FAQ') }}</h2>
      <div class="questions">

        <div v-for="(item, i) in content.faq" :key="'faq-' + i">
            <div class="question-title" :class="{'active': infoWrap === i}" @click.prevent="showInfoWrap(i)">
                <div class="line" data-inview="fadeInUp" data-delay="200">
                    <h3 class="main-title small">{{ item.heading }}</h3>
                    <div class="plus-icon"></div>
                </div>
            </div>
            <div class="question-reponse" :class="{'active': infoWrap === i}">
                <div v-html="item.text"></div>
            </div>
        </div>
      </div>
    </section>

    <section class="work" id="work" v-if="pageIsLoaded">
      <div class="title-wrap">
        <h2 class="main-title">{{ content.heading3 }}</h2>
      </div>

      <div class="carousel-work" >
        <div class="carousel-cell" v-for="(item, i) in content.videoSlider" :key="'imgs-' + i">
          <a :href="item.videoUrl" class="video glightbox">
            <img src="@/assets/img/play.svg" class="play" alt="Play">
          </a>
          <img :src="item.image.url" :alt="item.image.title">
        </div>
      </div>

      <button @click="previous2()" class="button-left"><img src="@/assets/img/arrow-left.svg" alt="Arrow left"></button>
      <button @click="next2()" class="button-right"><img src="@/assets/img/arrow-right.svg" alt="Arrow right"></button>

    </section>

    <section class="work light" id="gallery" v-if="pageIsLoaded">
      <div class="title-wrap">
        <h2 class="main-title">{{ content.heading4 }}</h2>
      </div>

      <div class="carousel-gal">
        <div class="carousel-cell" v-for="(item, i) in content.imagesSlider" :key="'gal-' + i">
          <img :src="item.image.url" :alt="item.image.title">
        </div>
      </div>

      <button @click="previous3()" class="button-left"><img src="@/assets/img/arrow-left.svg" alt="Arrow left"></button>
      <button @click="next3()" class="button-right"><img src="@/assets/img/arrow-right.svg" alt="Arrow right"></button>

    </section>

    <section class="contact" id="contact" v-if="pageIsLoaded">
      <h2 class="main-title">Contact</h2>
      <div class="bloc-contact" data-inview="fadeInUp" data-delay="200">
        <h3 class="main-title small">{{ globals.contact.contactCard[0].fullname }}</h3>
        <p class="regular-text">{{ globals.contact.contactCard[0].description }}</p>
        <div class="infos">
          <a :href="'tel:' + globals.contact.contactCard[0].tel">{{ globals.contact.contactCard[0].tel }}</a>
          <a :href="'mailto:'+globals.contact.contactCard[0].email+'?Subject=Information Request'"><img src="@/assets/img/mail.svg" alt="Mail icon"></a>
        </div>
      </div>
      <div class="bloc-contact" data-inview="fadeInUp" data-delay="400" v-if="globals.contact.contactCard && globals.contact.contactCard[1] && globals.contact.contactCard[1].fullname">
        <h3 class="main-title small">{{ globals.contact.contactCard[1].fullname }}</h3>
        <p class="regular-text">{{ globals.contact.contactCard[1].description }}</p>
        <div class="infos">
          <a :href="'tel:' + globals.contact.contactCard[1].tel">{{ globals.contact.contactCard[1].tel }}</a>
          <a :href="'mailto:'+globals.contact.contactCard[1].email+'?Subject=Information Request'"><img src="@/assets/img/mail.svg" alt="Mail icon"></a>
        </div>
      </div>
    </section>

    <footer class="main-footer" v-if="pageIsLoaded">
        <p class="regular-text">
            {{  globals.footer.legals }}
            <br/>
            <a class="footer-external-link" v-if="$route.meta.lang === 'en'" target="_blank" href="https://www.mels-studios.com/en/privacy-policy/">Privacy Policy</a><span v-if="$route.meta.lang === 'en'">&nbsp; | &nbsp;</span>
            <a class="footer-external-link" v-if="$route.meta.lang === 'en'" target="_blank" href="https://www.mels-studios.com/wp-content/uploads/2022/06/MELS_Termes_conditions_2022_EN-1.pdf">Terms of Use</a>

            <a class="footer-external-link" v-if="$route.meta.lang === 'fr'" target="_blank" href="https://www.mels-studios.com/fr/politique-de-confidentialite/">Politique de confidentialité</a><span v-if="$route.meta.lang === 'fr'">&nbsp; | &nbsp;</span>
            <a class="footer-external-link" v-if="$route.meta.lang === 'fr'" target="_blank" href="https://www.mels-studios.com/wp-content/uploads/2022/06/MELS_Termes_conditions_2022_FR-1.pdf">Termes d'utilisation</a>
        </p>
      <a href="https://elmire.ca/" target="_blank" class="credits"><p class="regular-text">Conception Elmire</p><img src="@/assets/img/elmire.png" alt="Elmire logo"></a>
    </footer>

    <div class="soft-protection" v-if="softProtection">
      <img src="@/assets/img/logo.png" class="logo" alt="Logo Studio MELS">
    </div>
  </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/AnimationsLayer'
import { mapState, mapGetters } from 'vuex'
import Flickity from 'flickity'
import GLightbox from 'glightbox';

export default {

  data() {
    return {
      softProtection: false,
      infoWrap: 0,
      carousel: null,
      carousel2: null,
      carousel3: null,
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      },
      flickityOptions2: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      },
      flickityOptions3: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      },
      position: 0
    }
  },

  watch: {
    pageIsLoaded: {
      handler(pageIsLoaded) {
        if (pageIsLoaded) {
          // Page loaded with Data

          setTimeout(() => {
            const carousel = document.querySelector('.carousel-tech')
            this.carousel = new Flickity(carousel, this.flickityOptions)
            this.carousel.on( 'change', () => {
                this.position =  this.carousel.selectedIndex
            })

            const carousel2 = document.querySelector('.carousel-work')
            this.carousel2 = new Flickity(carousel2, this.flickityOptions2)
            this.carousel2.on( 'change', () => {
                this.position =  this.carousel2.selectedIndex
            })

            const carousel3 = document.querySelector('.carousel-gal')
            this.carousel3 = new Flickity(carousel3, this.flickityOptions3)
            this.carousel3.on( 'change', () => {
                this.position =  this.carousel3.selectedIndex
            })

            initInViewAnimations()

            setTimeout(() => {
                this.carousel.resize()
                this.carousel2.resize()
                this.carousel3.resize()

                GLightbox()
            }, 2000);
          }, 475);
        }
      },
      immediate: true,
    },
  },

  methods: {
    showInfoWrap(value) {
      if(this.infoWrap === value) {
        this.infoWrap = 0
      } else {
        this.infoWrap = value
      }
    },
    next() {
      this.carousel.next();
    },
    previous() {
      this.carousel.previous();
    },
    next2() {
      this.carousel2.next();
    },
    previous2() {
      this.carousel2.previous();
    },
    next3() {
      this.carousel3.next();
    },
    previous3() {
      this.carousel3.previous();
    },
    goTo(pos) {
      this.carousel.select( pos )
    }
  },

  mounted() {

  },

  computed: {
    contentSlider() {
      if (!this.contentIsLoaded) {
        return []
      }

      return this.content.contentSlider
    },
    pageIsLoaded() {
      return this.contentIsLoaded && this.globalsIsLoaded
    },
    ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
    ...mapGetters(['seo', 'content', 'globals']),
  },
}
</script>

<style lang="scss" scoped>
</style>
